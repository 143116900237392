.video {
    font-family: seasideresort;
}

.video-heading {
    color: #fcd404;
    padding-top: 15px;
    padding-bottom: 30px;
    font-size: xx-large;
    z-index: 1;
    margin-right: 20px;
    position: absolute;
    right: 0;
}

.video-subheading {
    font-size: x-large;
}

.work-col {
    height: 50vh;
}

.work-image {
    width: 100%;
}

.page-3 {
    width: 100%;
    height: 100vh;
}

.work-image-container {

    background-size: 100%;
}

.work-links div a {
    color: #fcd404;
    font-family: seasideresort;
    font-size: xx-large;
}

.work-links {
    position: relative;
    text-align: center;
}

.news {
    width: 100%;
}

.sub-links {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sub-links div:nth-child(1) {
    margin-bottom: 20px;
}

.sub-links div:nth-child(2) {
    margin-top: 20px;
}

.sub-links div a {
    text-decoration: unset;
    color: white;
}

@media screen and (max-width:480px) {

    .video-heading {
        font-size: medium;
    }

    .work-links div a {
        font-size: medium;
    }

    .sub-links div:nth-child(1) {
        position: relative;
        top: 25%;
    }

    .sub-links div:nth-child(2) {
        position: relative;
        top: 70%;
    }

    .sub-links {
        height: 100%;
    }
}