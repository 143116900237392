.contact {
    height: 100vh;
    font-family: seasideresort;
}

.contact-heading {
    color: #fcd404;
    padding-top: 10px;
    padding-bottom: 30px;
    font-size: xx-large;
    text-align: right;
    margin-right: 20px;
}

.email {
    display: flex;
    color: #fcd404;
}

.email div a {
    color: white;
}

.email-text {
    padding-left: 10px;
}

.copyright {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: small;
}

.social-links {
    display: flex;
    justify-content: right;
    margin-right: 20px;
}

.social-links div {
    height: 32px;
    width: 32px;
    margin-right: 10px;
}

.social-content div:nth-child(2) {
    margin-right: 8px;
}

.social-links div:nth-last-child(1) {
    margin-right: unset;
}

.social {
    padding-top: 10px;
    margin: auto;
}

.social-content {
    height: 600px;
}

.social-content .col {
    margin: auto 70px;
}

.card-body.contact-body img {
    padding: 15px 0px;
}

div.card-title.h5 {
    padding-bottom: 15px;
}

.contact-body .card-subtitle {
    color: white;
    padding-bottom: 15px;
}

.contact-body.card-body {
    margin-left: 15px;
}

.height-174 {
    height: 174px;
}

@media screen and (max-width:480px) {

    .contact-heading {
        font-size: medium;
    }

    .card-body.contact-body {
        text-align: center;
        margin: 0px 15px;
    }

    .email {
        justify-content: center;
    }

    div.card-title.h5 {
        text-align: center;
    }

    .social-links {
        justify-content: center;
        margin-right: unset;
    }

    .social-content .col {
        margin: unset;
    }

    .social {
        align-content: center;
        padding-top: unset;
    }
}