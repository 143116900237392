.App {
  text-align: center;
  background-color: black;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card {
  border-radius: unset;
  border: unset;
  background-color: unset;
  color: unset;
  font-weight: 100;
}

.main-heading {
  background-color: #ffbf00;
  width: 100%;
  opacity: 0.5;
  left: 0;
  right: 0;
  text-align: center;
}


.all-links {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.divider {
  border: 0.5px solid white;
}

.all-links-content {
  display: flex;
  width: 150px;
  justify-content: space-between;
}

.col-3 img {
  width: 100%
}

.projects-row {
  margin-bottom: 25px;
}

.poster {
  display: flex;
  flex-direction: row;
}

.poster-item {
  width: 50%;
}

.poster-item-content {
  color: #fcd404;
  height: 50px;
}

.header {
  display: flex;
  font-family: seasideresort;
  justify-content: center;
  font-size: xx-large;
}

.bohemian,
.koala {
  padding-right: 10px;
}

.koala {
  display: flex;
  color: #282828;
}

.new-container {
  z-index: 2;
  height: 100vh;
  position: absolute;
  overflow-y: scroll;
  scrollbar-width: none;
}

.container-upper,
.container-lower {
  height: 100vh;
}

.container-lower {
  font-family: seasideresort;
  display: flex;
  align-items: center;
  justify-content: center;
  /* filter: grayscale(1); */
}

.text-container {
  font-family: bohemiansoul;
  font-size: large;
}

.logo-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  z-index: 4;
}

.flipImage {
  width: 60%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.gray {
  filter: grayscale(1);
}

.opacity {
  opacity: 0.6;
}

#scrollImage {
  transition: filter 0.5s ease;
}

.color-change {
  filter: grayscale();
}

.modal-close-header {
  position: absolute;
  right: 0;
  z-index: 1051;
  justify-content: end;
  border-bottom: unset;
}

.modal-close-header button {
  background-color: unset;
  border: none;
}

.ftco-section {
  padding: 3em 0;
}

.wrap {
  width: 100%;
  overflow: hidden;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
}

.loginLogo {
  width: 50%;
}

.login-wrap {
  width: 50%;
  position: relative;
  background: #fff;
  font-weight: 300;
  color: black;
}

.admin {
  background-color: #f8f9fd;
  font-family: bohemiansoul;
}

.heading-section {
  color: black;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.form-group .label {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #000;
  font-weight: 700;
}

.form-group {
  position: relative;
  margin-bottom: 1rem;
}

.btn.btn-primary {
  background: #ffbf00 !important;
  border: 1px solid #ffbf00 !important;
  color: #fff !important;
}

.admin-dashboard {
  background-color: #fff;
  color: #000;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.form-group.d-flex label {
  width: 20%;
}

table thead,
table tbody {
  text-align: left;
}

@media screen and (max-width:480px) {

  .text-container {
    font-size: small;
  }

  .loginLogo,
  .login-wrap {
    width: 100%;
  }
}