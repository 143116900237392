.commercials,
.shorts {
    font-family: seasideresort;
}

.commercials-heading,
.shorts-heading {
    color: #fcd404;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: xx-large;
    text-align: right;
    margin-right: 20px;
}

.card-onhover:hover {
    cursor: pointer;
    opacity: 0.7;
}

.modal-content {
    background-color: unset;
    border: unset;
    border-radius: unset;
}

.modal-content iframe {
    width: 100%;
    height: 100vh;
}

.col-4.col-width {
    width: 27%;
}

@media screen and (max-width:480px) {

    .commercials-heading,
    .shorts-heading {
        font-size: medium;
    }

    .card-title-commercial {
        font-size: smaller;
    }
}