.main-header {
    position: absolute;
    z-index: 3;
    width: 100%;
}

.main-header-container {
    display: flex;
    height: 100%;
}

.main-logo {
    width: 50%;
}

.logo-section {
    margin: auto;
}

.menu-section {
    position: absolute;
    right: 0;
}

.menu-btn {
    background: unset;
    border: unset;
    color: unset;
    margin-top: 7px;
}

.menu-wrapper {
    z-index: 3;
    position: fixed;
    width: 300px;
    height: 100%;
    background: #fcd404;
    right: 0;
    top: 0;
    opacity: 0.8;
}

.menu-open {
    visibility: visible;
}

.menu-closed {
    visibility: hidden;
}

.menu-items {
    margin-top: 80px;
    font-size: xx-large;
    font-family: seasideresort;
}

.menu-item {
    margin-top: 10px;
}

.menu-item a {
    color: white;
    text-decoration: unset;
}

.menu-item:nth-child(2n) a {
    color: #282828;
}

.navbar-nav li a {
    color: white;
    font-size: small;
    font-family: seasideresort;
}

.navbar-nav li:nth-child(2n) a {
    color: #fcd404;
}

.navbar-nav li a:hover {
    color: #fcd404;
}

.navbar-nav li:nth-child(2n) a:hover {
    color: white;
}

.nav-item {
    margin-right: 15px;
}

.logo-bklogomark {
    width: 7%;
    position: fixed;
    left: -15px;
}

.logo-bklogomark img {
    width: 50%;
    margin-top: 15px;
}

@media screen and (max-width:480px) {
    .logo-bklogomark {
        width: 20%;
        left: -10px;
    }

    .menu-section {
        width: 100%;
    }

    .navbar-toggler {
        position: absolute;
        right: 0;
        top: 5px;
    }

    .main-logo {
        width: 60%;
    }
}