.about {
    height: 100vh;
    z-index: 0;
    font-family: seasideresort;
    background-color: black;
    position: relative;
}

.about-heading {
    color: #fcd404;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: xx-large;
    text-align: right;
    margin-right: 20px;
}

.card-body {
    font-family: bohemiansoul;
    text-align: justify;
}

.card-body p {
    margin-bottom: 5px;
    font-size: medium;
}

.card-subtitle {
    color: #fcd404;
    padding-bottom: 30px;
}

.profile {
    margin-top: 30px;
    margin-bottom: 30px;
}

.image-col {
    display: flex;
}

.about-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width:480px) {

    .about-heading {
        font-size: medium;
    }

    .card-body-about .card-title {
        font-size: medium;
    }

    .card-body-about .card-text {
        font-size: x-small;
    }

    .card-body-about .card-subtitle {
        font-size: medium;
    }

    .about-image {
        margin-bottom: 20px;
    }

    .about-image img {
        width: 40%;
    }

}